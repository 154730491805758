import adRefresh from './sda/sda_modern';
import { rapidBeaconClick } from './utils/rapid';
const { wafer } = window;

wafer?.ready(() => {
    adRefresh();

    // handle wafer autocomplete select and delete instrumentation
    wafer.on('autocomplete:select', rapidBeaconClick);
    wafer.on('autocomplete:deleted', rapidBeaconClick);
});
