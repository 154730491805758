/**
 * This file handles refreshing the ads, it is a port of: https://git.ouroath.com/frontpage/fp-www/blob/master/my_sources/modules/sda/sda_modern.js
 * To monitor ad-calls you can filter fc.php in browsers network tab
 *
 * On high level these are the use-cases:
 * - When user scrolls to LREC3 div, make ad-call with these positions: LREC3,LREC4,MON2
 * - When user closes modal make ad-call with ad positions which are in viewport
 *     - exception is if LREC3,LREC4 are in viewport then include MON2 also in ad-call
 * - Also configure AUTO event in DARLA, this configuration is used by DARLA to make time based ad refreshes
 * - When user switches tabs rotate ads that are in viewport
 * - If we are showing pencil gemini ad in place of LDRB, then rotate that ad on modal close by making wafer-fetch call
 * */
interface YahooWindow extends Window {
    DARLA: any;
}

const DEFAULT_YAHOO = {
    context: {
        bucket: '',
        bucketId: '',
        device: '',
        intl: '',
        lang: '',
        meta: {},
        region: '',
        rid: '',
        site: '',
    },
    i13n: {},
};

export default (): void => {
    const WIN = window as YahooWindow & typeof globalThis; // eslint-disable-line no-undef
    const DOC = document;
    const {
        adsConfig,
        YAHOO = DEFAULT_YAHOO,
        DARLA: DARLA_INSTANCE = null,
        wafer: waferInstance,
    } = WIN;
    const waferBase = waferInstance.base || ({} as typeof waferInstance.base);

    const clientSideAdsConfig = {};
    // events
    const AD_AUTO_EVENT = 'AUTO'; // this is the ractual auto rotate event name
    const AD_FETCH_EVENT_ID = 'adRotateEvt'; // this is really ad fetch event for ads in viewport, despite the word "rotate" in the event's name
    const AD_TABSWITCH_EVENT = 'tabswitch';
    const DARLA_READY_ON_ASYNC = 'darlaReadyOnAsync';
    const PAGE_SCROLL_EVENT = 'scroll';
    const PAGE_VISIBILITY_EVENT = 'pageVisibilityChange';
    const VIEWER_CLOSE_AD_EVENT = 'homepage-viewer'; // this is ad call event that viewer makes
    const VIEWER_CLOSE_EVENT = 'viewerClosed';
    const VIEWER_OPEN_EVENT = 'viewerOpened';

    // legacy selectors, LREC2 means more like an ad container
    const AD_NODE_ID_PREFIX = 'sda-';
    const LREC_SELECTIVE_AD_CONTAINER_SEL = 'sticky-lrec2-footer';
    const LREC4_CONTAINER = AD_NODE_ID_PREFIX + 'LREC4';

    // default configs
    const DEFAULT_SPACEID = '2023538075';
    const LREC_LOAD_OFFSET = 100;

    // this replaces LREC2 specific variables in old files
    // configs that should be shared across functions after getting init
    let baseAdsConfig;
    let LRECAdsContainer;
    let baseAdPositionMeta;

    // native leader board
    const NATIVE_LEADER_BOARD_CLASS = 'native-leaderboard-ad';
    const NATIVE_LEADER_BOARD_REFRESH_CLASS = 'gemini-ad-refresh';
    let nativeLeaderBoardElem;

    // ad rotate related
    let darlaTimeStamp;
    let tabswitchRotateTime;
    let viewerOpened = false;
    let tabswitchAdCallCount = 0;
    const getCurrentTime =
        Date.now ||
        function () {
            return new Date().getTime();
        };

    if (!DARLA_INSTANCE) {
        WIN.addEventListener(
            DARLA_READY_ON_ASYNC,
            function handleDarlaReadyOnAsync() {
                initSdaJS();
            }
        );

        return;
    }

    initSdaJS();

    /**
     * init ads custom JS after pageload
     * @returns {void}
     */
    function initSdaJS() {
        if (!adsConfig) {
            return;
        }
        baseAdsConfig = adsConfig.rotation;

        if (
            !baseAdsConfig ||
            !adsConfig?.positions?.[baseAdsConfig.currentpos]
        ) {
            return;
        }

        adsConfig.clientPositionMeta.positions.forEach(function (item) {
            clientSideAdsConfig[item.id] = item;
        });

        baseAdPositionMeta = clientSideAdsConfig[baseAdsConfig.currentpos];

        LRECAdsContainer = DOC.getElementById(
            AD_NODE_ID_PREFIX + baseAdPositionMeta.pos
        );
        if (!DARLA_INSTANCE || !LRECAdsContainer) {
            console.error('No Darla instance or LRECAdsContainer');
            return;
        }

        // calculate offset for pre-loading LREC
        const LRECLoadOffset = {
            offsetX: 0,
            offsetY: parseInt(baseAdsConfig.offset, 10) || LREC_LOAD_OFFSET,
        };
        waferInstance.on(PAGE_SCROLL_EVENT, fetchAdsWhenVisible);
        WIN.addEventListener(VIEWER_OPEN_EVENT, function handleViewerOpen() {
            viewerOpened = true;
            // might wanna stop auto rotate, not sure if viewer is handling it
        });
        WIN.addEventListener(VIEWER_CLOSE_EVENT, function handleViewerClose() {
            viewerOpened = false;
            handleReturnFromModalAdFetch();
        });

        setTimeout(function () {
            addAndStartAutoEvent();
            registerTabswitch();
            fetchAdsWhenVisible(LRECLoadOffset);
        }, 300);
        const nativeLeaderboardElements = DOC.getElementsByClassName(
            NATIVE_LEADER_BOARD_CLASS
        );
        if (nativeLeaderboardElements && nativeLeaderboardElements.length > 0) {
            nativeLeaderBoardElem = nativeLeaderboardElements[0];
        }
    }

    /**
     * using wafer util to detects if a given DOM element is in viewport
     * @function isNodeInViewport
     * @param {Object} elem the config object to iterate through
     * @param {Object} offset optional params to specify offsetX and offsetY
     * @returns {Boolean} weather or not the element is in view
     */
    function isNodeInViewport(elem, offset?) {
        offset = offset || {};
        return waferInstance.utils.elementInView(
            elem,
            offset,
            waferBase.viewport
        );
    }

    /**
     * as we scroll keep checking if the ads become in-viewport. Once it does, fetch the ad, and detach from scroll event
     * @function fetchAdsWhenVisible
     * @param {Object} offset optional params to specify offsetX and offsetY for viewport detection
     * @returns {void}
     */
    function fetchAdsWhenVisible(offset) {
        // we will not try to detect the visibility of an ad node if modal is open
        if (viewerOpened) {
            return;
        }
        if (isNodeInViewport(LRECAdsContainer, offset)) {
            fetchAds();
            // once we've attempt to fetch ads, we are done with fetching ads based on viewport ad detection.
            // we will remove the listener on scroll for performance
            waferInstance.removeListener(
                PAGE_SCROLL_EVENT,
                fetchAdsWhenVisible
            );
        }
    }

    /**
     * register to handle page visibility change event
     * @function registerTabswitch
     * @returns {void}
     */
    function registerTabswitch() {
        tabswitchRotateTime = baseAdsConfig.tabswitchrotate || 0;
        if (!tabswitchRotateTime) {
            return;
        }
        darlaTimeStamp = getCurrentTime();
        waferInstance.on(PAGE_VISIBILITY_EVENT, handleTabSwitch);
        if (WIN.addEventListener) {
            WIN.addEventListener('focus', function () {
                handleTabSwitch({ isActive: true });
            });
            WIN.addEventListener('blur', function () {
                handleTabSwitch({ isActive: false });
            });
        }
    }

    /**
     * For ad boost if group rotation is configured then add additional positions to ad-call
     * @function addGroupRotationPositions
     * @param {String} adPos The ad position
     * @param {Object} adBoostGroupConfig the ad-boost group config
     * @param {Array} adCallPositions array of ad positions
     * @returns {void}
     */
    function addGroupRotationPositions(
        adPos,
        adBoostGroupConfig,
        adCallPositions
    ) {
        const extraPositions =
            (adBoostGroupConfig[adPos].extrapositions &&
                adBoostGroupConfig[adPos].extrapositions.split(',')) ||
            [];
        if (adBoostGroupConfig[adPos].inview) {
            let groupAdsInView = true;
            const inviewAdPos = [].concat(
                adBoostGroupConfig[adPos].inview.split(',')
            );
            // if any other position also has to be in view before we can include additional positions
            for (const iAdPos of inviewAdPos) {
                const iPosSettings = DARLA_INSTANCE.posSettings(iAdPos);
                if (iPosSettings) {
                    const iAdNode = document.getElementById(iPosSettings.dest);
                    if (!isNodeInViewport(iAdNode)) {
                        groupAdsInView = false;
                    }
                }
            }
            if (groupAdsInView) {
                const groupVAds = [].concat(inviewAdPos, extraPositions);
                for (const nAdPos of groupVAds) {
                    if (adCallPositions.indexOf(nAdPos) < 0) {
                        adCallPositions.push(nAdPos);
                    }
                }
            }
        } else {
            for (const gAdPos of extraPositions) {
                if (adCallPositions.indexOf(gAdPos) < 0) {
                    adCallPositions.push(gAdPos);
                }
            }
        }
    }

    /**
     * handle page visibility change event, fire darla tabswitch event, restart auto rotation
     * @function handleTabSwitch
     * @param {Object} eventPayload - switch event
     * @returns {void}
     */
    function handleTabSwitch(eventPayload) {
        if (!eventPayload.isActive) {
            darlaTimeStamp = getCurrentTime();
            return;
        }
        if (eventPayload.isActive) {
            const now = getCurrentTime();
            if (
                now - darlaTimeStamp < tabswitchRotateTime ||
                DARLA_INSTANCE.inProgress()
            ) {
                return;
            }
            if (!WIN._darlaAutoEvt) {
                WIN._darlaAutoEvt = getAutoEvent();
            }

            const autoEvtAdPositions = WIN._darlaAutoEvt.ps;
            let adPositions = ['MON']; // according to ads team 'MON' needs to be added even tho it's not going to do time based auto rotate
            const adCallPositions = [];
            const adBoostGroupConfig = baseAdsConfig.adboostgrouprotation;
            if (WIN?.pageloadValidAds?.indexOf('UBALREC') >= 0) {
                // for ad block users we have UBA positions
                adPositions.push('UBALREC');
                adPositions.push('UBALREC2');
                adPositions.push('UBALREC3-9');
            }
            if (WIN?.pageloadValidAds?.indexOf('UBALDRB') >= 0) {
                adPositions.push('UBALDRB');
            }
            let modalAutoEvent = {};
            if (viewerOpened) {
                const viewerEventSettings =
                    DARLA_INSTANCE.evtSettings('homepage-viewer');
                if (viewerEventSettings?.ps) {
                    adPositions = viewerEventSettings.ps.split(',');
                }
                modalAutoEvent = DARLA_INSTANCE.evtSettings('AUTO');
            } else {
                for (const key in autoEvtAdPositions) {
                    if (autoEvtAdPositions.hasOwnProperty(key)) {
                        adPositions.push(key);
                    }
                }
            }

            for (const adPos of adPositions) {
                const posSettings = DARLA_INSTANCE.posSettings(adPos);
                if (posSettings?.dest) {
                    const adNode = document.getElementById(posSettings.dest);
                    if (
                        adNode &&
                        isNodeInViewport(adNode, 0) &&
                        adNode.offsetHeight > 1
                    ) {
                        adCallPositions.push(adPos);
                        if (
                            !viewerOpened &&
                            adBoostGroupConfig &&
                            adBoostGroupConfig[adPos]
                        ) {
                            addGroupRotationPositions(
                                adPos,
                                adBoostGroupConfig,
                                adCallPositions
                            );
                        }
                    }
                }
            }

            if (adCallPositions.length) {
                // @ts-ignore
                let siteAttrs = modalAutoEvent.sa || WIN._darlaAutoEvt.sa;
                if (baseAdsConfig.togglesa && tabswitchAdCallCount % 2 === 0) {
                    siteAttrs = baseAdsConfig.togglesa;
                }
                tabswitchAdCallCount++;
                const tabswitchEvent = {
                    experience: WIN._darlaAutoEvt.experience,
                    name: AD_TABSWITCH_EVENT,

                    npv: 1,

                    property: 'fp',

                    ps: adCallPositions.join(','),

                    ref: WIN.location.href,

                    sa: siteAttrs,

                    secure: WIN._darlaAutoEvt.secure,
                    // @ts-ignore
                    sp: modalAutoEvent.sp || WIN._darlaAutoEvt.sp,

                    ssl: WIN._darlaAutoEvt.ssl,
                    // @ts-ignore
                    ult: modalAutoEvent.ult || WIN._darlaAutoEvt.ult,
                };
                WIN.DARLA.add(tabswitchEvent);
                WIN.DARLA.event(AD_TABSWITCH_EVENT);
            }
            return;
        }
    }

    /**
     * fetch selective ads based on ads config
     * @function fetchAds
     * @returns {void}
     */
    function fetchAds() {
        if (!baseAdsConfig) {
            return;
        }

        const LREC3PositionMeta = baseAdPositionMeta;

        let LREC3_AD_POSITION;
        let LREC4_AD_POSITION;
        let MON2_AD_POSITION;

        // these will be populated based on configs, and will be fed to the ad fetch event
        let selectiveRenderPos = ''; // ad positions to fetch
        const selectiveRenderPosMeta = [LREC3PositionMeta];

        const LREC4Position = baseAdsConfig.lrec4pos || 'LREC4';
        const LREC4Selector = AD_NODE_ID_PREFIX + LREC4Position;
        const LREC4Configs = clientSideAdsConfig[LREC4Position];

        // default ad meta info
        const LREC4PositionMeta =
            LREC4Configs ||
            generateAdsPositionConfig(
                LREC4Position,
                LREC4Selector,
                LREC4Selector + '-iframe',
                300,
                250
            );

        const MON2Position = baseAdsConfig.mon2pos || 'MON2';
        const MON2Selector = AD_NODE_ID_PREFIX + MON2Position;
        const MON2Configs = clientSideAdsConfig[MON2Position];
        const MON2PositionMeta =
            MON2Configs ||
            generateAdsPositionConfig(
                MON2Position,
                MON2Selector,
                MON2Selector + '-iframe',
                300,
                600
            );

        if (LREC3PositionMeta) {
            LREC3_AD_POSITION = LREC3PositionMeta.pos;
            LREC3PositionMeta.id = LREC3_AD_POSITION;
            selectiveRenderPos += LREC3_AD_POSITION;
        }
        if (baseAdsConfig.lrec4enabled) {
            LREC4_AD_POSITION = LREC4PositionMeta.pos;
            selectiveRenderPos += ',' + LREC4_AD_POSITION;
            selectiveRenderPosMeta.unshift(LREC4PositionMeta);
        }
        if (baseAdsConfig.mon2enabled) {
            MON2_AD_POSITION = MON2PositionMeta.pos;
            selectiveRenderPos += ',' + MON2_AD_POSITION;
            selectiveRenderPosMeta.push(MON2PositionMeta);
        }
        const selectiveRenderFetchEventId =
            'fetch_selective_ad_' + LREC3_AD_POSITION.toLowerCase(); // the ad fetch event name
        const selectiveRenderEvent = generateEvents(
            selectiveRenderPos,
            selectiveRenderFetchEventId
        ); // ad fetch event object

        // if during ad fetch, there's darla instance in progress, that means we are already fetch ads in viewer
        // now we wanna abort what's going on previously
        if (DARLA_INSTANCE.inProgress()) {
            const eventName = DARLA_INSTANCE.inProgress();
            if (eventName && eventName === VIEWER_CLOSE_AD_EVENT) {
                DARLA_INSTANCE.abort();
                DARLA_INSTANCE.add(selectiveRenderEvent);
                DARLA_INSTANCE.addPos(selectiveRenderPosMeta);
                DARLA_INSTANCE.event(selectiveRenderFetchEventId);
            } else {
                fetchAdsLater();
                return;
            }
        } else {
            // if this is not enabled, the selective spots will not be rendered client side on scroll
            // (they will be taken care of by server side rendering)
            if (baseAdsConfig.lrec2selectiveenabled) {
                DARLA_INSTANCE.add(selectiveRenderEvent);
                DARLA_INSTANCE.addPos([selectiveRenderPosMeta]);
                DARLA_INSTANCE.event(selectiveRenderFetchEventId);
            }
        }

        addAndStartAutoEvent();
    }

    /**
     * refresh ads that are in viewport and start auto rotation after coming back from modal
     * @function handleReturnFromModalAdFetch
     * @returns {void}
     */
    function handleReturnFromModalAdFetch() {
        if (!WIN.pageloadNonCollapsedAds) {
            return;
        }
        // first we check if there are pageload ads in view, we will refresh those for sure
        let adFetchEvent;
        const adPosToRefresh = [];
        const pageloadNonCollapsedAds = WIN.pageloadNonCollapsedAds;
        const pageloadAdPosToCheck = [
            'LREC',
            'MAST',
            'LDRB',
            'UBALDRB',
            'UBALREC',
        ]; // this excludes FPAD ad which will be handles separately

        for (const adPos of pageloadAdPosToCheck) {
            if (pageloadNonCollapsedAds.indexOf(adPos) >= 0) {
                const adNode = DOC.getElementById(AD_NODE_ID_PREFIX + adPos);
                if (adNode && isNodeInViewport(adNode)) {
                    adPosToRefresh.push(adPos);
                }
            }
        }
        // outcome should be something like ['LREC', 'MAST']
        if (adPosToRefresh.length) {
            adFetchEvent = generateEvents(adPosToRefresh, AD_FETCH_EVENT_ID);
            executeDarlaEvents(adFetchEvent, true);
        } else if (
            pageloadNonCollapsedAds.indexOf('FPAD') >= 0 &&
            WIN.safeframeOptinPositions &&
            WIN.safeframeOptinPositions.FPAD
        ) {
            adFetchEvent = generateEvents('FPAD', AD_FETCH_EVENT_ID);
            const darlaConfig = DARLA_INSTANCE.config();
            const FPADAdNode = DOC.getElementById(AD_NODE_ID_PREFIX + 'FPAD');
            const enableRotation = !!darlaConfig?.positions?.FPAD;
            if (enableRotation && FPADAdNode && isNodeInViewport(FPADAdNode)) {
                executeDarlaEvents(adFetchEvent);
            }
        }

        if (!baseAdsConfig) {
            return;
        }

        // now we deal with selective ads, refresh and start auto rotate
        const LRECSelectiveAdContainer = DOC.getElementById(
            LREC_SELECTIVE_AD_CONTAINER_SEL
        );
        const LREC4AdContainer = DOC.getElementById(LREC4_CONTAINER);
        if (
            (LRECSelectiveAdContainer &&
                isNodeInViewport(LRECSelectiveAdContainer)) ||
            (LREC4AdContainer && isNodeInViewport(LREC4AdContainer))
        ) {
            const adFetchPositions = [];
            const LREC3AdConfig =
                clientSideAdsConfig?.[baseAdsConfig.currentpos];
            const LREC3AdPosition = LREC3AdConfig?.pos || '';
            LREC3AdPosition && adFetchPositions.push(LREC3AdPosition);
            // if MON2 ads are enabled and are in view, we fetch those instead of LREC4
            if (baseAdsConfig.mon2enabled) {
                adFetchPositions.push('MON2');
                // either MON2 node isn't presented or is hidden
                if (baseAdsConfig.lrec4enabled && baseAdsConfig.lrec4pos) {
                    adFetchPositions.push(baseAdsConfig.lrec4pos);
                }
            } else if (baseAdsConfig.lrec4enabled && baseAdsConfig.lrec4pos) {
                adFetchPositions.push(baseAdsConfig.lrec4pos);
            }

            if (adFetchPositions.length) {
                const selectiveRenderFetchEventId =
                    'fetch_selective_ad_' + LREC3AdPosition.toLowerCase();
                const selectiveRenderEvent = generateEvents(
                    adFetchPositions.join(','),
                    selectiveRenderFetchEventId
                );
                DARLA_INSTANCE.add(selectiveRenderEvent);
                DARLA_INSTANCE.event(selectiveRenderFetchEventId);
            }
        }

        // in case something in modal has stopped auto rotation, reset the rotation timer
        addAndStartAutoEvent();

        if (nativeLeaderBoardElem) {
            // if native leader board is in viewport refresh it
            if (isNodeInViewport(nativeLeaderBoardElem)) {
                reloadNativeLeaderBoard();
            }
        }
    }

    /**
     * if there are pageload ads still loading, we will hold off and retry to load selective ads after pageload ads are done loading
     * @function fetchAdsLater
     * @returns {void}
     */
    function fetchAdsLater() {
        let retry = 0;
        const retryTimer = setInterval(function () {
            if (retry > 20) {
                clearInterval(retryTimer);
                return;
            }

            if (!DARLA_INSTANCE.inProgress()) {
                clearInterval(retryTimer);
                fetchAds();
            }
            retry++;
        }, 500);
    }

    /**
     * generate ad position configs
     * @function generateAdsPositionConfig
     * @param {string} pos - position
     * @param {string} clean - clean
     * @param {string} dest - dest
     * @param {number} width - width of add
     * @param {number} height - height of add
     * @returns {Object} ads position config object
     */
    function generateAdsPositionConfig(pos, clean, dest, width, height) {
        return {
            clean,
            dest,
            h: height,
            metaSize: true,
            pos,
            supports: false,
            w: width,
        };
    }

    /**
     * generate ad events - can be ad fetch event, ad rotation event, all depending on the params passed in
     * @function generateEvents
     * @param {string|Array|Object} eventPosition the ad positions to be fetched/auto rotated
     * @param {string} eventName name of the ad event, can be `adRotateEvt`, `AUTO`, or others
     * @param {Object} extraParams additional params to be added to the event object (shallow merge)
     * @returns {Object} generated ad event
     */
    function generateEvents(eventPosition, eventName, extraParams?) {
        const pageContext = YAHOO.context;
        const ultMeta = {
            pg: {
                device: pageContext.device,
                intl: pageContext.intl,
                property: 'fp',
                rid: pageContext.rid,
                test: pageContext.bucket,
            },
        };
        const event = {
            experience: {
                bucket: pageContext.bucket || '',
                pd: 'modal',
                pt: 'index',
                rid: pageContext.rid || '',
                sid: YAHOO?.i13n?.currentSID || '',
            },
            name: eventName,
            npv: 1,
            property: 'fp',
            ps: eventPosition,
            ref: WIN.location.href,
            sa: '',
            secure: 1,
            sp: YAHOO?.i13n?.SPACEID || DEFAULT_SPACEID,
            ssl: 1,
            ult: ultMeta,
        };

        // some extra params are required for auto events.
        // Notice they won't have same fields as the original event obj and only requires shallow merge
        for (const param in extraParams) {
            if (extraParams.hasOwnProperty(param)) {
                event[param] = extraParams[param];
            }
        }

        // this is set in darla assets we load on pageload and it's usually true
        if (WIN.bucketSAEnabled) {
            let siteAttr =
                (pageContext.bucket &&
                    'Y-BUCKET="' + pageContext.bucket + '"') ||
                '';
            // setting random stuff
            if (WIN.facCustomTimout && parseInt(WIN.facCustomTimout, 10) > 0) {
                siteAttr += ' ctout=' + WIN.facCustomTimout;
            }

            if (WIN.flSAKey && WIN.flInstalled) {
                siteAttr += ' ' + WIN.flSAKey + '=' + WIN.flInstalled;
            }

            if (WIN.segBlob) {
                let segSA = '';
                let delimiter = '';
                for (const blobKey in WIN.segBlob) {
                    if (WIN.segBlob.hasOwnProperty(blobKey)) {
                        segSA +=
                            delimiter + blobKey + ':' + WIN.segBlob[blobKey];
                        delimiter = ';';
                    }
                }
                siteAttr += ' rs="' + segSA + '"';
            }
            siteAttr += ' ' + (WIN.customSiteAttr || '');
            event.sa = siteAttr;
        }
        return event;
    }

    /**
     * generate ad auto rotation event
     * @function getAutoEvent
     * @returns {Object} generated ad auto rotation event
     */
    function getAutoEvent() {
        const LRECRotationDisabled = baseAdsConfig.disablerotation;
        const autoRotationPosList = {};
        const LRECCurrentPos = baseAdsConfig.currentpos.toLowerCase();
        // this is for the convenience of the loop below
        baseAdsConfig[LRECCurrentPos + 'enabled'] = true;
        const defaultRotationTime = baseAdsConfig.defaultrt;
        const pageloadNonCollapsedAds = WIN.pageloadNonCollapsedAds || [];
        const adsPositionsName = [
            LRECCurrentPos,
            'lrec4',
            'mon2',
            'mast',
            'ldrb',
            'lrec',
            'mon',
        ];

        if (LRECRotationDisabled) {
            return null;
        }

        for (const adName of adsPositionsName) {
            if (
                baseAdsConfig[adName + 'enabled'] ||
                (baseAdsConfig[adName + 'rt'] &&
                    pageloadNonCollapsedAds.indexOf(adName.toUpperCase()) !==
                        -1)
            ) {
                autoRotationPosList[adName.toUpperCase()] = {
                    autoIV: 1,
                    autoMax: 25,
                    autoRT: baseAdsConfig[adName + 'rt'] || defaultRotationTime,
                };
            }
        }
        const autoEventParams = {
            autoDDG: 1,
            autoIV: 1,
            autoMax: 25,
            autoRT: baseAdsConfig.autoeventrt,
            autoStart: 1,
        };

        if (baseAdsConfig.grouprotation) {
            // @ts-ignore
            autoEventParams.groups = baseAdsConfig.grouprotation;
        }
        return generateEvents(
            autoRotationPosList,
            AD_AUTO_EVENT,
            autoEventParams
        );
    }

    /**
     * add auto rotation event to WIN._darlaAutoEvt to start the ad rotation
     * @function addAndStartAutoEvent
     * @returns {void}
     */
    function addAndStartAutoEvent() {
        if (baseAdsConfig.autorotate) {
            if (DARLA_INSTANCE.inProgress()) {
                setTimeout(function () {
                    addAndStartAutoEvent();
                }, 500);
            } else {
                // setting the auto event to WIN._darlaAutoEvt and add to darla instance will start the ad rotation
                WIN._darlaAutoEvt = getAutoEvent();
                if (WIN._darlaAutoEvt) {
                    DARLA_INSTANCE.add(WIN._darlaAutoEvt);
                    if (!DARLA_INSTANCE.isAutoOn()) {
                        DARLA_INSTANCE.startAuto();
                    }
                }
            }
        }
    }

    /**
     * Reloads native leader board ad using wafer-fetch
     * @function reloadNativeLeaderBoard
     * @returns {void}
     */
    function reloadNativeLeaderBoard() {
        if (!nativeLeaderBoardElem) {
            return;
        }
        let refreshNode = nativeLeaderBoardElem.getElementsByClassName(
            NATIVE_LEADER_BOARD_REFRESH_CLASS
        );
        if (refreshNode && refreshNode.length > 0) {
            refreshNode = refreshNode[0];
            const fetchUrl = refreshNode.getAttribute('data-url');
            const xhrBody = refreshNode.getAttribute('data-body');
            if (
                fetchUrl &&
                xhrBody &&
                WIN.wafer &&
                WIN.wafer.utils &&
                WIN.wafer.utils.fetchWithCache
            ) {
                WIN.wafer.utils
                    .fetchWithCache<{ html?: string }>(fetchUrl, {
                        body: xhrBody,
                        cache: 0,
                    })
                    .then(function wfSuccess(response) {
                        if (response && response.html) {
                            refreshNode.innerHTML = response.html;
                            const newLeaderBoardAd =
                                refreshNode.getElementsByClassName(
                                    NATIVE_LEADER_BOARD_CLASS
                                );
                            if (
                                newLeaderBoardAd &&
                                newLeaderBoardAd.length > 0
                            ) {
                                nativeLeaderBoardElem.replaceWith(
                                    newLeaderBoardAd[0]
                                );
                                const nativeLeaderboardElements =
                                    DOC.getElementsByClassName(
                                        NATIVE_LEADER_BOARD_CLASS
                                    );
                                if (
                                    nativeLeaderboardElements &&
                                    nativeLeaderboardElements.length > 0
                                ) {
                                    nativeLeaderBoardElem =
                                        nativeLeaderboardElements[0];
                                    // wafer sync to fire gemini beaons
                                    WIN.wafer.base.sync(
                                        nativeLeaderBoardElem.parentElement
                                    );
                                }
                            }
                        }
                    });
            }
        }
    }

    /**
     * Actual execution of ad fetching.
     * Depending on if darla is fetching non-viewer ad at the moment a fetch is being initialized, we will fetch ad and restart the auto rotation event accordingly.
     * @function executeDarlaEvents
     * @param {Object} adFetchEvent any custom Ad fetch event
     * @param {Boolean} restartAutoEvent restart the auto rotation event when true
     * @returns {void}
     */
    function executeDarlaEvents(adFetchEvent, restartAutoEvent?) {
        restartAutoEvent = restartAutoEvent || false;
        if (DARLA_INSTANCE.inProgress()) {
            if (DARLA_INSTANCE.inProgress() === VIEWER_CLOSE_AD_EVENT) {
                DARLA_INSTANCE.abort();
                if (
                    restartAutoEvent &&
                    WIN._darlaAutoEvt &&
                    WIN._darlaAutoEvt.ps
                ) {
                    DARLA_INSTANCE.add(WIN._darlaAutoEvt);
                }
                DARLA_INSTANCE.add(adFetchEvent);
                DARLA_INSTANCE.event(AD_FETCH_EVENT_ID);
            }
        } else {
            if (restartAutoEvent && WIN?._darlaAutoEvt?.ps) {
                DARLA_INSTANCE.add(WIN._darlaAutoEvt);
            }
            DARLA_INSTANCE.add(adFetchEvent);
            DARLA_INSTANCE.event(AD_FETCH_EVENT_ID);
        }
    }
};
